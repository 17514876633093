/* Home.css */
.home {
  padding-top: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

/* Hero Section */
.hero-section {
  position: relative;
  background: url('back2.png') no-repeat center center;
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5vw;
  color: white;
}

.hero-title {
  padding-top: 1px;
  font-size: 6vw; /* Responsive text */
  font-weight: bold;
  text-shadow: 2px 2px 10px yellow;
}

.hero-text {
  font-size: 2.5vw;
  margin-bottom: 20px;
  text-shadow: 1px 1px 5 yellow;
}

.cta-btn {
  font-size: 1.8vw;
  padding: 12px 24px;
  border-radius: 8px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  padding-inline-end: 12px;
}

.cta-btn:hover {
  background: #0056b3;
}

/* Robotics Kits Section */
.kits-section {
  padding: 60px 5vw;
  text-align: center;
  background: linear-gradient(to bottom, #ffffff, #f0f0f0);
  border-top: 4px solid #007bff;
  border-bottom: 4px solid #007bff;
}

.kits-title {
  font-size: 3vw;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.kits-text {
  font-size: 2vw;
  color: #555;
  max-width: 90%;
  margin: 10px auto;
  line-height: 1.6;
}

.kits-btn {
  font-size: 2vw;
  padding: 10px 20px;
  border: 2px solid #007bff;
  color: #007bff;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
}

.kits-btn:hover {
  background: #007bff;
  color: white;
}


/* WhatsApp Button */
.whatsapp-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #25D366;
  color: white;
  padding: 8px 16px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1.5vw;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.whatsapp-button a {
  color: white;
  font-size: 1.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.whatsapp-icon {
  font-size: 3vw;
  margin-right: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    min-height: 70vh;
    padding: 10px;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-text {
    font-size: 1rem;
  }

  .kits-title {
    font-size: 2rem;
  }

  .kits-text {
    font-size: 1rem;
  }

  .kits-btn {
    font-size: 1rem;
    padding: 8px 16px;
  }


  .whatsapp-button {
    font-size: 1rem;
    padding: 6px 12px;
  }

  .whatsapp-icon {
    font-size: 1.5rem;
  }
}

.news-section {
  text-align: center;
}

.news-card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.news-card:hover {
  transform: translateY(-5px);
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.card-body p {
  flex-grow: 1; /* Ensures even spacing in cards */
}


