/* General section styling */
.services-section {
  
  background-color: #f9f9f9;
}

/* Add spacing between services */
.service-block {
  padding: 50px 20px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
}

/* Alternating background colors for better separation */
.service-block:nth-child(odd) {
  background-color: #ffffff;
}

.service-block:nth-child(even) {
  background-color: #eef5ff;
}

/* Elevate service block slightly on hover */
.service-block:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

/* Image styling */
.service-block .img-container {
  text-align: center;
  margin-bottom: 20px;
}

.service-block .img-fluid {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

/* Subtle zoom effect on hover */
.service-block .img-fluid:hover {
  transform: scale(1.05);
}

/* Text Styling */
.service-block h3 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.service-block p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  text-align: center;
}

/* Center the button */
.service-block .btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Button Styling */
.service-block .btn-primary {
  background-color: #007bff;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.service-block .btn-primary:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .service-block {
    padding: 40px 15px;
  }

  .service-block h3 {
    font-size: 1.5rem;
  }

  .service-block p {
    font-size: 1rem;
  }

  .service-block .btn-primary {
    font-size: 0.9rem;
    padding: 10px 20px;
  }


.services-section .service-block {
  background: #f8f9fa; /* Light grey background */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px; /* More spacing between sections */
}
.services-section h3 {
  color: #007bff; /* Bootstrap primary blue */
}
}
