.about-page {
  background-color: #f8f9fa; /* Light grey background */
  padding-bottom: 50px;
}

h1, h2 {
  color: #1e2a38;
  font-weight: bold;
}

.list-group-item {
  font-size: 18px;
  padding: 10px 15px;
  background-color: #ffffff;
  border-left: 5px solid #007bff; /* Blue left border */
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.text-muted {
  font-size: 18px;
}
